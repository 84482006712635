export const useSubmenuVisibility = (close = () => {}) => {
  const activeId = ref<string | null>(null)
  const subMenusVisible = ref(false)

  const closeSubMenus = () => {
    activeId.value = null
    subMenusVisible.value = false
  }

  const onClick = (id: string) => {
    activeId.value = id
    subMenusVisible.value = true
  }

  const onPageLoadingStateChange = () => {
    closeSubMenus()
    close()
  }

  watch(() => useLoadingStore().isLoading, onPageLoadingStateChange)

  return {
    activeId,
    subMenusVisible,
    onClick,
    closeSubMenus,
  }
}
